export const ROUTES = {
  SIGNIN: '/sign-in',
  ROOT: {
    APP: '/',
    SETTINGS: '/settings',
  },
};

export const CONSTANT = {
  CARD: {
    PAGINATION: {
      PAGE_SIZE: 20,
    },
  },
};
