import { takeLatest, put } from 'redux-saga/effects';
import { actionEnums, enumsKeys } from '@/core/reducers/enums';
import { actionsError } from '@/core/reducers/error';
import { Payload } from '../interfaces';

function* handleGetGlobalEnum({ payload }: Payload) {
  try {
    yield put(
      actionEnums.set({
        key: enumsKeys.GLOBAL,
        value: {},
      }),
    );
    yield put(
      actionEnums.set({
        key: enumsKeys.LOADED,
        value: true,
      }),
    );
    return;
  } catch (error: any) {
    yield put(actionsError.setError({ message: error.message }));
  }
}

const sagas = [
  takeLatest(actionEnums.getGlobalEnums.type, handleGetGlobalEnum),
];

export { sagas as default };
