import { createSlice } from '@reduxjs/toolkit';

export const appKeys = {
  NAME: 'app',
  google_loaded: 'google_loaded',
};

const INTIAL_STATE = {
  [appKeys.google_loaded]: false,
};

const appSlice = createSlice({
  name: appKeys.NAME,
  initialState: INTIAL_STATE,
  reducers: {
    set(state, action) {
      const { key, value } = action.payload;
      const newState: any = { ...state };
      newState[key] = value;
      return newState;
    },
    reset(state) {
      return {
        ...INTIAL_STATE,
        [appKeys.google_loaded]: state[appKeys.google_loaded],
      };
    },
    resetKeys(state, action) {
      const { keys } = action.payload;
      const newState: any = { ...state };
      keys.forEach((k: string) => {
        newState[k] = null;
      });
      return newState;
    },
    setGoogleLoaded(state) {
      const newState: any = { ...state };
      newState.google_loaded = true;
      return newState;
    },
  },
});

export const appActions = appSlice.actions;

export default appSlice.reducer;
