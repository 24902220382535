import styled, { useTheme } from 'styled-components';
// import loadingImage from '@/assets/images/logohhcolor.svg';
// import loadingWhiteImage from '@/assets/images/logohh.svg';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const LoadingViewStyled = styled.div`
  height: 100vh;
  width: 100%;
  text-align: center;
  display: flex;
  background: ${({ theme }) => theme.colors.whiteSoft};
  align-items: center;
`;

const MarginDiv = styled.div`
  width: 350px;
  margin: auto;
  color: ${({ theme }) => theme.colors.white};
  padding-bottom: 10rem;
`;

const LoadingView = () => {
  const theme = useTheme();
  return (
    <LoadingViewStyled>
      <MarginDiv>
        <Spin
          style={{ marginRight: '1rem' }}
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 24,
                color: theme.colors.primary,
              }}
              spin
            />
          }
        />
      </MarginDiv>
    </LoadingViewStyled>
  );
};
export default LoadingView;
