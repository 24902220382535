import { combineReducers } from 'redux';
import user from './users';
import error from './error';
import enums from './enums';
import persist from './persist';
import app from './app';
import library from './library';

const rootReducer = combineReducers({
  user,
  error,
  enums,
  persist,
  app,
  library,
});
export default rootReducer;
