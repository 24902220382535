import { createSlice } from '@reduxjs/toolkit';

export const libraryKeys = {
  NAME: 'library',
};

const INTIAL_STATE = {};

const librarySlice = createSlice({
  name: libraryKeys.NAME,
  initialState: INTIAL_STATE,
  reducers: {
    set(state: { [key: string]: any }, action) {
      const { key, value } = action.payload;
      // const newState: any = { ...state };
      state[key] = value;
      // return newState;
    },
    reset(state) {
      return {
        ...INTIAL_STATE,
      };
    },
    resetKeys(state: { [key: string]: any }, action) {
      const { keys } = action.payload;
      // const newState: any = { ...state };
      keys.forEach((k: string) => {
        state[k] = null;
      });
      // return newState;
    },
    setLastItemPlayed(state: { [key: string]: any }, action) {
      const { book } = action.payload;
      // const newState: any = { ...state };
      state[libraryActions.lasItemPlayed.type] = book;
      if (state[libraryActions.getLibrary.type]?.length) {
        const foundIndex = state[libraryActions.getLibrary.type].findIndex(
          (bo: { relativePath: string }) =>
            bo.relativePath === book.relativePath,
        );
        if (foundIndex !== -1) {
          state[libraryActions.getLibrary.type][foundIndex] = book;
        }
      }
      // return newState;
    },
    setFilterForm(_state, _action) {},
    getLibrary(_state, _action) {},
    lasItemPlayed(_state, _action) {},
    updateItemLibrary(_state, _action) {},
    updateLastItemPlayed(_state, _action) {},
  },
});

export const libraryActions = librarySlice.actions;

export default librarySlice.reducer;
