import { createSelector } from 'reselect';
import { actionUsers, userKeys } from '@/core/reducers/users';

const userFromState = (state: any) => state[userKeys.NAME];

const getUser = createSelector(
  [userFromState],
  user => user[actionUsers.setUser.type],
);

const getSigninEmail = createSelector(
  [userFromState],
  user => user[actionUsers.initLoginEmail.type],
);

const getSagasLoaded = createSelector(
  [userFromState],
  user => user[userKeys.SAGAS_LOADED],
);

const isGoogleOAuth = createSelector(
  [userFromState],
  user => user[actionUsers.googleOAuthSetup.type],
);

const selectors = {
  getUser,
  getSagasLoaded,
  getSigninEmail,
  isGoogleOAuth,
};
export { selectors as default };
