import { createGlobalStyle, DefaultTheme } from 'styled-components';
import { Helmet } from 'react-helmet-async';

export const GoogleFontInjection = () => {
  return (
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="true"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap"
        rel="stylesheet"
      ></link>
    </Helmet>
  );
};

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: {
      black: string;
      white: string;
      whiteSoft: string;
      primary: string;
      secundary: string;
      secundaryHover: string;
      grayLight: string;
      softGray: string;
      gray: string;
      blue: string;
      lightBlue: string;
      success: string;
      error: string;
      warning: string;
      link: string;
      softYellow: string;
      yellow: string;
      darkPrimary: string;
      lightPrimary: string;
      red: string;
      disable: string;
      inputBackground: string;
      movements: string;
      gradientBlue: string;
      brand: string;
      separator: string;
      hoverMain: string;
    };
    fonts: {
      Inter: string;
    };
    sizes: {
      panelMaxWidth: number;
      panelBorderRadius: number;
    };
  }
}

const AppTheme: DefaultTheme = {
  colors: {
    black: '#242320',
    white: '#fffefc',
    whiteSoft: '#F4F4F5',
    primary: '#3488D1',
    secundary: '#4800ff',
    secundaryHover: '#7944ff',
    grayLight: '#f4f4f4',
    softGray: '#b5b5b5',
    gray: '#8F8E95',
    blue: '#838EFA',
    lightBlue: '#0091ff',
    success: '#26D76D',
    error: '#FF7575',
    warning: '#e38420',
    link: '#0091ff',
    softYellow: '#ffebb0',
    yellow: '#FFDE1A',
    darkPrimary: '#030613',
    lightPrimary: '#f4f4f5',
    red: '#fa5a59',
    disable: '#29304b',
    inputBackground: '#0b1a49',
    movements: '#161616',
    gradientBlue: '#108ee9',
    brand: '#4145AE',
    separator: '#DCDCDC',
    hoverMain: '#f3f5f8',
  },
  fonts: {
    Inter: "'Inter', sans-serif",
  },
  sizes: {
    panelMaxWidth: 600,
    panelBorderRadius: 7,
  },
};

export const GlobalStyle = createGlobalStyle`
  body {
    margin:0;
    font-family: ${AppTheme.fonts.Inter};
  }
  p {
    font-family: ${AppTheme.fonts.Inter};
  }
  .ant-form-item-explain-error {
    font-size: 11px;
    font-family: ${AppTheme.fonts.Inter};
  }
  .ant-tooltip {
    font-family: ${AppTheme.fonts.Inter};
    font-size: 13px;
  }
  .ant-message-notice-content {
    font-family: ${AppTheme.fonts.Inter};
    background: ${AppTheme.colors.secundaryHover} !important;
    color: ${AppTheme.colors.white} !important;
    svg {
      color: ${AppTheme.colors.white} !important;
    }
  }
  .form_item_no_margin {
    margin-bottom: 2px;
  }
  .alignNumberCenter {
    text-align: center;
  }
  .textEllipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .ant-table-wrapper .ant-table {
    font-family: ${AppTheme.fonts.Inter} !important;
  }
  .ant-form {
    font-family: ${AppTheme.fonts.Inter} !important;
  }
  .recharts-legend-wrapper {
    bottom: 0px !important;
  }
  .ant-skeleton.ant-skeleton-active .ant-skeleton-title, 
  .ant-skeleton.ant-skeleton-active .ant-skeleton-paragraph>li,
  .ant-skeleton.ant-skeleton-active .ant-skeleton-avatar,
  .ant-skeleton.ant-skeleton-active .ant-skeleton-button,
  .ant-skeleton.ant-skeleton-active .ant-skeleton-input,
  .ant-skeleton.ant-skeleton-active .ant-skeleton-image {
    background: linear-gradient(90deg, rgb(0 0 0 / 29%) 25%, rgb(0 0 0 / 13%) 37%, rgb(0 0 0 / 18%) 63%);
    background-size: 400% 100%;
    
  }
  .ant-skeleton.ant-skeleton-active .ant-skeleton-paragraph>li {
    :nth-child(2) {
      background: linear-gradient(90deg, rgb(0 0 0 / 40%) 25%, rgb(0 0 0 / 25%) 37%, rgb(0 0 0 / 31%) 63%);
      background-size: 400% 100%;
    }
  }
  .modalMessage {
    font-family: ${AppTheme.fonts.Inter};
    .ant-modal-content {
      background: ${AppTheme.colors.black};
    }
    .ant-modal-confirm-title,
    .ant-modal-confirm-content {
      color: ${AppTheme.colors.white};
    }
    .ant-modal-body {
      padding: 0px;
      padding-bottom: 15px;
    }
    .ant-modal-footer {
      .ant-btn[disabled] {
        background-color: ${AppTheme.colors.disable};
        color: ${AppTheme.colors.whiteSoft};
      }
      button {
        background: ${AppTheme.colors.secundary};
        color: ${AppTheme.colors.white};
      }
    }
  }
  .ant-popconfirm-description {
    font-family: ${AppTheme.fonts.Inter};
    color: #3b3b3b;
  }
  .ant-popconfirm-buttons {
    button {
      padding: 0px 10px;
      font-family: ${AppTheme.fonts.Inter};
    }
    .ant-btn-primary {
      background: ${AppTheme.colors.primary};
    }
  } 
  .ant-dropdown-menu {
    padding: 8px 0px !important;
    .ant-dropdown-menu-item {
      font-family: ${AppTheme.fonts.Inter};
      padding: 7px 15px !important;
      font-weight: 500 !important; 
      :hover {
        background: #F1F2F4 !important;
      }
    }
  }
  .ant-slider {
    flex: 1;
    .ant-slider-rail {
      background-color: ${AppTheme.colors.separator} !important;
    }
    .ant-slider-track {
      background-color: #87a0ba;
    }
    .ant-slider-handle {
      display: none;
    }
    :hover {
      .ant-slider-handle {
        display: block;
      }
    }
  }
  .ant-popover-inner {
    padding: 0px !important;
  }
`;

export default AppTheme;
