import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GenericError } from '@/core/interfaces';

export const errorKeys = {
  NAME: 'error',
};
const ERROR_INITIAL: GenericError = {
  status: false,
};

const errorSlice = createSlice({
  name: errorKeys.NAME,
  initialState: ERROR_INITIAL,
  reducers: {
    setChangePasswordError(
      state,
      action: PayloadAction<GenericError | undefined>,
    ) {
      const errorState = action?.payload;
      if (errorState) {
        return {
          ...state,
          changePasswordError: { ...errorState, status: true },
        };
      }
      return { ...state, changePasswordError: { status: true } };
    },
    setError(state, action: PayloadAction<GenericError | undefined>) {
      const errorState = action?.payload;
      if (errorState) {
        return { ...state, ...{ ...errorState, status: true } };
      }
      return { ...state, status: true };
    },
    clearError() {
      return ERROR_INITIAL;
    },
    setCustomError(state, action: PayloadAction<GenericError | undefined>) {
      if (!action.payload) {
        return state;
      }
      const { code, message } = action.payload;
      if (!code) {
        return state;
      }
      if (!state.custom) {
        state.custom = {};
      }
      return void (state.custom[code] = message);
    },
  },
});

export const actionsError = errorSlice.actions;

export default errorSlice.reducer;
