import { createSlice } from '@reduxjs/toolkit';

export const persistKeys = {
  NAME: 'persist',
  USER_TOKEN: 'usertoken',
  USER_PERSIST: 'user_persist',
  DESKTOP_VIEW: 'desktop_view',
};

const INTIAL_STATE = {
  [persistKeys.USER_TOKEN]: null,
  [persistKeys.USER_PERSIST]: null,
  [persistKeys.DESKTOP_VIEW]: null,
};

const persistSlice = createSlice({
  name: persistKeys.NAME,
  initialState: INTIAL_STATE,
  reducers: {
    set(state, action) {
      const { key, value } = action.payload;
      const newState: any = { ...state };
      newState[key] = value;
      return newState;
    },
    reset(state) {
      return INTIAL_STATE;
    },
    resetKeys(state, action) {
      const { keys } = action.payload;
      const newState: any = { ...state };
      keys.forEach((k: string) => {
        newState[k] = null;
      });
      return newState;
    },
    setDesktopView(state, action) {
      const { view } = action.payload;
      return void (state[persistKeys.DESKTOP_VIEW] = view);
    },
  },
});

export const actionPersist = persistSlice.actions;

export default persistSlice.reducer;
