import { ROUTES } from '@/config/constant';
import { lazy, useEffect } from 'react';
import {
  Routes,
  Route,
  BrowserRouter,
  Navigate,
  useNavigate,
} from 'react-router-dom';
import DynamicSagaLoader from './components/DynamicSagaLoder';
import userSelector from '@/core/selectors/users';
import { LazyRender } from './components/LazyRender';
import LoadingView from './components/LoadingView';
import { useAppDispatch, useAppSelector } from '@/core';
import { actionUsers } from '@/core/reducers/users';
import { Authentication } from '@/core/interfaces';
import moment from 'moment-timezone';
require('moment/locale/es.js');
moment.locale('es');

const PageNotFound = lazy(() => import('@/app/pages/NotFound'));
const SignInPage = lazy(
  () => import(/* webpackChunkName: "SignIn" */ '@/app/pages/SigninPage'),
);

const HomeRouter = lazy(
  () => import(/* webpackChunkName: "HomeRouter" */ '@/app/pages/HomeRouter'),
);

const ValidAuthentication = (props: {
  user: Authentication;
  children: JSX.Element;
}) => {
  const { children, user } = props;
  return user === undefined ? (
    <LoadingView />
  ) : user?.email ? (
    <LazyRender>{children}</LazyRender>
  ) : (
    <Navigate
      to={{
        pathname: ROUTES.SIGNIN,
      }}
    />
  );
};

const SagasValidation = (props: {
  sagasLoaded: boolean;
  children: JSX.Element;
}) => {
  const { children, sagasLoaded } = props;
  return !sagasLoaded ? <LoadingView /> : <LazyRender>{children}</LazyRender>;
};

const AppRouter = () => {
  const dispatch = useAppDispatch();
  const sagasLoaded = useAppSelector(state =>
    userSelector.getSagasLoaded(state),
  );
  const authentication: Authentication = useAppSelector(state =>
    userSelector.getUser(state),
  );

  useEffect(() => {
    if (authentication === undefined && sagasLoaded) {
      dispatch(actionUsers.getAuthentication());
    }
  }, [dispatch, authentication, sagasLoaded]);

  return (
    <BrowserRouter>
      <LazyRender>
        <DynamicSagaLoader />
      </LazyRender>
      <Routes>
        <Route
          path={ROUTES.SIGNIN}
          element={
            <SagasValidation sagasLoaded={sagasLoaded}>
              <SignInPage />
            </SagasValidation>
          }
        />
        <Route
          path={`${ROUTES.ROOT.APP}*`}
          element={
            <ValidAuthentication user={authentication}>
              <HomeRouter />
            </ValidAuthentication>
          }
        />
        <Route
          path={'*'}
          element={
            <LazyRender>
              <PageNotFound />
            </LazyRender>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
